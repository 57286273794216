<template>
  <div class="Navigation" :style="{background:bgColor,borderBottom:`1px solid ${color}`}">
    <!-- <img src="~img/31.png" class="logo" @click='$router.push({ path: "/" })'> -->
    <!-- <p class="logo" :style="{color:color}" @click='$router.push({ path: "/" })'>富富 SIDE PROJECT</p> -->
    <img src="~img/41.png" class="logo" v-if="color == '#ff7030'" @click='$router.push({ path: "/" })'>
    <img src="~img/42.png" class="logo" v-if="color == '#64471a'" @click='$router.push({ path: "/" })'>
    <img src="~img/About_Logo.svg" class="logo" v-if="color == '#4aaeff'" @click='$router.push({ path: "/" })'>
    <img src="~img/51.png" class="logo" v-if="color == '#0a9161'" @click='$router.push({ path: "/" })'>
    <template v-if="color == '#ff7030'">
      <img src="~img/32.png" class="right" v-show="!showClose" @click="showClose = true">
      <img src="~img/33.png" class="right" v-show="showClose" @click="showClose = false">
    </template>
    <template v-if="color == '#64471a'">
      <img src="~img/34.png" class="right" v-show="!showClose" @click="showClose = true">
      <img src="~img/43.png" class="right" v-show="showClose" @click="showClose = false">
    </template>
    <template v-if="color == '#4aaeff'">
      <img src="~img/About_Icon1.svg" class="right" v-show="!showClose" @click="showClose = true">
      <img src="~img/About_Icon2.svg" class="right" v-show="showClose" @click="showClose = false">
    </template>
    <template v-if="color == '#0a9161'">
      <img src="~img/53.png" class="right" v-show="!showClose" @click="showClose = true">
      <img src="~img/52.png" class="right" v-show="showClose" @click="showClose = false">
    </template>
    <ul v-show="showClose" :style="{color:color,background:bgColor}">
      <!-- <li @click='$router.push({ path: "/desksidea" })'>产品 SIDE A PROJECT</li>
      <li @click='$router.push({ path: "/project" })'>项目 PROJECT</li>
      <li @click='$router.push({ path: "/deskabout" })'>关于 ABOUT</li>
      <li>定制 SIDE B PROJECT</li> -->
      <li v-for="item in list" :key="item.id" @click='$router.push({ path: item.path })'
        :style="{borderBottom:`1px solid ${color}`}">{{item.name}}</li>
    </ul>
  </div>
</template>

<script>
// 0a9161
export default {
  props: ["bgColor", "color"],
  data() {
    return {
      showClose: false,
      list: [
        {
          id: 1,
          name: "产品 SHOP ALL",
          path: "/desksidea",
        },
        {
          id: 2,
          name: "拼朋 BE OUR PUZZ-BUD",
          path: "/side",
        },
        {
          id: 3,
          name: "动态 OUR STORIES",
          path: "/project",
        },
        {
          id: 4,
          name: "关注 FIND US",
          path: "/deskabout",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.Navigation {
  width: 100%;
  height: 54px;

  background: #fff;
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    width: 51.47%;
    margin-left: 4.93%;
  }
  .right {
    width: 25px;
    margin-right: 4.93%;
  }
  // ul {
  //   width: 100%;
  //   margin-top: 10px;
  //   color: #ff7030;
  //   background: #fff;
  //   li {
  //     height: 54px;
  //     border-bottom: 2px solid #ff7030;
  //     line-height: 54px;
  //     font-size: 19px;
  //     text-align: center;
  //     transition: 0.5s all;
  //   }
  //   // li:first-child {
  //   //   border-top: 2px solid #ff7030;
  //   // }
  // }
  ul {
    position: absolute;
    top: 54px;
    width: 100%;
    li {
      height: 54px;
      text-align: center;
      line-height: 54px;
      font-family: PHANBO, GLEXBL; //导航栏、筛选模块、底部跑马灯字体
      font-size: 19px;
    }
  }
}
</style>

